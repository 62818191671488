<template>
  <policy>
    <privacy-policy></privacy-policy>
  </policy>
</template>

<script>
import Policy from "@/layouts/Policy.vue"
import PrivacyPolicy from "@/components/policy/PrivacyPolicy.vue"

export default {
  components: { Policy, PrivacyPolicy }
}
</script>
